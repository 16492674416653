<template>
  <div class="verification">
    <div class="row align-items-center justify-content-between col-gap-90">
      <div class="col-md-6">
        <h1>
          Your <br />
          verification <br />
          code is :
        </h1>
        <verify-email @success="verified"></verify-email>
      </div>
      <div class="col-md-6">
        <div class="image">
          <img
            src="@/assets/images/Securitylogo.png"
            class="img-fluid"
            alt="Securitylogo-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    verifyEmail: () =>
      import(
        /* webpackChunkName: "verifyemail" */ "@/components/brand/VerifyEmail.vue"
      ),
  },
  methods: {
    verified() {
      this.$router.push("business-detail");
    },
  },
};
</script>

<style lang="scss">
.LoginRegister {
  .verification {
    @media screen and (max-width: 1600px) {
      .image {
        img {
          max-width: 480px;
        }
      }
    }
    @media screen and (max-width: 1300px) {
      .image {
        img {
          max-width: 375px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .image {
        img {
          max-width: 325px;
        }
      }
    }
    .form-control {
      height: 76px;
    }
  }
}
</style>
